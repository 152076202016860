import axios from 'axios';
import config from './config';
import store from "./store/configureStore";

const instance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(config => {
  try {
    config.headers['x-access-token'] = store.getState().auth.token;
  } catch (e) {
    // do nothing
  }

  return config;
});

export const polygons = axios.create({
  baseURL: config.atlasUrl
});

export const nominatimOpenstreetmap = axios.create({
  baseURL: config.nominatimOpenstreetmap
});

export default instance;
